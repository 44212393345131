import { Component } from 'solid-js'
import { SmallButtonProps } from '~/components/small-button/small-button.interfaces'
import styles from '~/components/small-button/small-button.module.scss'

const SmallButton: Component<SmallButtonProps> = ({ children, className, onClick, type, transparent, borderless, light, ...props }) => {
  const Icon = props.icon
  const cssClasses = [
    styles.button,
    borderless ? styles.borderless : '',
    transparent ? styles.transparent : '',
    light ? styles.light : '',
    className
  ].join(' ')

  return (
    <button
      class={cssClasses}
      onClick={onClick}
      type={type}
    >
      {Icon && (
        <Icon class={styles.icon} />
      )}
      {children}
    </button>
  )
}

export default SmallButton
